<template>
    <div class="main">
      <div class="main-item header p-5 bg-white">
        <div class="header-1 flex justify-between">
          <div class="header-1-1">我的应用 / 创建应用</div>
          <div class="header-1-2 flex">
          </div>
        </div>
      </div>
      <div class="main-body p-5 pr-24 pb-8 bg-white">
          <el-form :model="form" ref="form" :rules="rules" label-width="100px" >
              <el-form-item label="应用名称" prop='name' >
                  <el-input v-model="form.name" type="text" maxlength="20" show-word-limit
                  ></el-input>
              </el-form-item>
              <el-form-item label="应用描述" prop='remark'>
                  <!-- <el-input type="textarea" v-model="form.remark" class="height:134px"></el-input> -->
                  <el-input type="textarea" v-model="form.remark"  :autosize="{ minRows: 5, maxRows: 5}"  :show-word-limit='true'  :maxlength="200"></el-input>
              </el-form-item>
              <!-- <el-form-item>
                  <el-button type="primary" @click="clickSubmit">确定</el-button>
                  <el-button>取消</el-button>
              </el-form-item> -->
          </el-form>
          <div class="mt-16 flex justify-center">
              <el-button class="l-btn-primary"   @click="clickConfirm">确定</el-button>
              <span class="ml-10">
              <el-button class="l-btn-cancel"  @click="clickCancle">返回</el-button>
              </span>
          </div>
          
      </div>
    </div>
</template>
  <script>
  export default {
    // el: '', // string | Element
    name: '', // string
    // parent: '', // Vue instance
    // functional: false, // boolean
    // delimiters: ['{{', '}}'], // Array<string>
    // comments: false,
    components: {},// Object
    // directives: {}, // Object
    // filters: {}, // Object
    // extends: '', // Object | Function
    // mixins: [], // Array<Object>
    // inheritAttrs: true, // boolean
    // model: '', // { prop?: string, event?: string }
    props: {}, // Array<string> | Object
    data () {
      return {
          form:{
              naem:'',
              remark:''
          },
          rules:{
              name:[
  { required: true, message: '请输入应用名称', trigger: 'blur' },

              ]
          }
      }
    },
    computed: {}, // { [key: string]: Function | { get: Function, set: Function } }
    // watch: {}, // { [key: string]: string | Function | Object | Array }
    beforeCreate () {},
    created () {
    },
    beforeMount () {},
    mounted () {},
    beforeUpdate () {},
    updated () {},
    activated () {},
    deactivated () {},
    beforeDestroy () {},
    destroyed () {},
    methods: {
clickConfirm(){

 this.$refs.form.validate((valid) => {
   if(valid){
this.createPost(this.form.name,this.form.remark).then((res)=>{
this.$router.push({name:'MyApplicationDetail',query:{isvId:res.isvId}})
})
   }else{
     return
   }
 })




},
clickCancle(){
this.$router.push({name:'MyApplication'})
},
      createPost(name,remark){
       return this.$http('post','/op/website/isv/create',{
name,
remark
       },{
          /* mock: 22 */
        }).then(
//           res=>{
// this.tableData =res
//           }
        )
      },
    } // { [key: string]: Function }
    // template: '', // render // string
    // renderError: '' // (createElement: () => VNode, error: Error) => VNode
  }
  </script>
  <style lang="scss" scoped>
    .main-item{
      background: ffffff;
    }
  .header-1-1{
    border-left-width: 2px;
    padding-left: 10px;
    border-color: #333333;
  }
  .header-1-2{
    font-size: 14px;
  }
.main-body{
    margin-top: 22px;
}
.main ::v-deep .el-form-item__label{
    padding-right: 20px;
    // margin-right: 20px;
}
  </style>
  